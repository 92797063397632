import { Table } from 'react-bootstrap';
import PrintDialog from './printDialogCompornent';
import { useContext } from 'react';
import { PrintListContext } from '../provider/printListProvider';
import { LabelKbnContext } from '../provider/labelKbnProvider';

export default function LabelList(){
    const {printList,setPrintList} = useContext(PrintListContext);
    const { labelKbn, setLabelKbn} = useContext(LabelKbnContext);
    return(
        <div className='cookingList'>
          
        <Table hover className='bg-white'>
          <thead>
            {labelKbn == 'A' ?
              <tr className='text-center'>
                <th>喫食日</th>
                <th>連番</th>
                <th>名前</th>
                <th>食区分</th>
                <th>形態</th>
                <th>消費期限</th>
                <th>禁食者</th>
                <th></th>
              </tr>
              :
              <tr className='text-center'>
                <th>調理日</th>
                <th>連番</th>
                <th>施設名</th>
                <th>食区分</th>
                <th>料理名</th>
                <th>消費期限</th>
                <th></th>
               </tr>
            }
          </thead>

          <tbody>
            {printList.map((x,k)=>(
              labelKbn == 'A' ?
                <tr key={k}>
                  <td>{x.list_show.split(',')[2]}</td>
                  <td>{x.display_order_number}</td>
                  <td>{x.list_show.split(',')[0]}</td>
                  <td>{x.list_show.split(',')[5]}</td>
                  <td>{x.list_show.split(',')[6]}</td>
                  <td>{x.list_show.split(',')[7]}</td>
                  <td>{x.list_show.split(',').length == 9 && x.list_show.split(',')[8]}</td>
                  <td>
                    <PrintDialog select={x}></PrintDialog>
                  </td>
                </tr>
              :
                <tr key={k}>
                  <td>{x.list_show.split(',')[4]}</td>
                  <td>{x.display_order_number}</td>
                  <td>{x.list_show.split(',')[0]}</td>
                  <td>{x.list_show.split(',')[2]}</td>
                  <td>{x.list_show.split(',')[1]}</td>
                  <td>{x.list_show.split(',')[5]}</td>
                  <td>
                    <PrintDialog select={x}></PrintDialog>
                  </td>
                </tr>
            ))}
          </tbody>
        </Table>
        </div>
    )
}