import React, { useContext } from 'react';
import { Button } from 'react-bootstrap'
import { DateContext } from '../provider/dateProvider';
import { PrintFlagContext } from '../provider/printFlagProvider';
import { LabelKbnContext } from '../provider/labelKbnProvider';

export default function SearchList(){
  const { date, setDate } = useContext(DateContext);  
  const {printFlag, setPrintFlag} = useContext(PrintFlagContext);
  const { labelKbn, setLabelKbn} = useContext(LabelKbnContext);

  const selectDate = (e) => { setDate(e.target.value); }  //日付
  const selectPrintFlag = (e) => {setPrintFlag(e.target.value)} //印刷フラグ

  const printStart = () => {
    fetch("/api/WebLabelPrintInfo/putLabelInfo?date="+ date + "&flag=0&number=0&kbn=" + labelKbn);
  }

  const printStop = () => {
    fetch("/api/WebLabelPrintInfo/putLabelInfo?date="+ date + "&flag=1&number=0&kbn=" + labelKbn);
  }

    return(
        <div className="row d-flex d-flex align-items-center pb-2 bd-highlight">
            <div className='col-lg-3 col-4 me-auto bd-highlight'>
              <input type='date' className='form-control' defaultValue={date} onChange={selectDate}></input>
            </div>

            <div className='col-lg-3 col-4 me-auto bd-highlight' onChange={selectPrintFlag} defaultValue={printFlag}>
              <select className='form-control'>
                <option value="0">未印刷</option>
                <option value="1">印刷済</option>
                <option value="2">全て</option>
              </select>
            </div>

            <div className="col-2 bd-highlight">
                <Button size="lg" onClick={printStart} className='px-4'>印 刷</Button>
            </div>
            <div className="col-2 bd-highlight">
                <Button variant="danger" size="lg" onClick={printStop} className='px-4'>中 止</Button>
            </div>
        </div>
    )
}